@import "../../../node_modules/glightbox/dist/css/glightbox";

.goverlay {
  background-color: __get($theme-colors, "white");
  .glightbox-mobile & {
    background: __get($theme-colors, "white");
  }
  .gslide-description {
    background-color: transparent;
    .gdesc-inner {
      padding-top: $grid-gutter-width * 0.5;
      .gslide-desc {
        color: $black;
      }
    }
  }
}

.glightbox-mobile .glightbox-container .gslide-description {
  flex: 0 0 auto;
  padding: 0;
}

.gcontainer {
  .gslide-media {
    box-shadow: none;
    @include media-breakpoint-down(lg) {
      img {
        max-height: 75vh;
      }
    }
  }
  .gslide-description {
    background: transparent !important;
    position: relative !important;
    flex: 0;
    .gdesc-inner {
      padding-top: $grid-gutter-width * 0.5;
      .gslide-desc {
        text-align: center;
        color: $gray-100;
      }
    }
  }
  .gbtn {
    &.gdownload {
      position: absolute;
      opacity: 1;
      top: 40px;
      right: 132px;
      width: 60px;
      height: 60px;
      background-color: transparent !important;
    }

    &.gclose {
      opacity: 1;
      top: 40px;
      right: 40px;
      width: 60px;
      height: 60px;
      background-color: transparent !important;

      svg {
        width: 40px;
        height: 40px;

        path {
          fill: $black;
        }
      }
    }

    &.gcount {
      position: absolute;
      top: 40px;
      left: 40px;
      font-size: 30px;
      @include media-breakpoint-up(xxl) {
        background-color: transparent !important;
      }
    }

    &.gnext,
    &.gprev {
      width: 48px;
      height: 48px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      background-color: transparent !important;

      svg {
        width: 30px;
        height: 30px;
        filter: brightness(0) saturate(0);
        align-self: center;
        justify-self: center;
      }

      &.disabled {
        display: none;
      }
    }

    &.gprev {
      transform: rotate(180deg);
    }
  }
}

.glightbox-closing {
  #gcount,
  #gdownload {
    opacity: 0;
  }
}
