// ce-frame wrapper for all content elements
.ce-frame {
  @extend .mx-auto;

  @each $breakpoint, $maxWidth in $ce-wrapper-max-width {
    max-width: 100%;

    @include media-breakpoint-up($breakpoint) {
      max-width: $maxWidth;
    }
  }

  margin-bottom: $grid-gutter-width * 3;
  margin-top: $grid-gutter-width * 3;

  &.no-followup-margin + & {
    margin-top: 0;
  }

  &-layout {
    &__container {
      @extend .container;

      & + & {
        margin-top: $grid-gutter-width / 2;
      }

      & + figure {
        margin-top: $grid-gutter-width / 2;
      }
    }

    &__row {
      @include make-row();
    }

    &__column {
      .ce-frame-layout-0 &,
      .ce-frame-layout-teaser &,
      .ce-frame-type-theme_image & {
        @include make-col-ready();
        @include make-col(12);
        @extend .mx-auto;

        @include media-breakpoint-up(ultrawide) {
          @include make-col(8);
        }
      }

      .ce-frame-type-list-bbboilerplate_pi3 &,
      .ce-frame-type-theme_page_teaser &,
      .ce-frame-type-theme_event_teaser & {
        @include media-breakpoint-up(ultrawide) {
          @include make-col(10);
        }
      }

      .ce-frame-type-list-bbboilerplate_pi4 & {
        @include media-breakpoint-up(ultrawide) {
          @include make-col(10);

          &.ce-frame-layout__column-speaker {
            @include make-col(8);
          }
        }
      }

      &.hero-column {
        @include media-breakpoint-up(md) {
          @include make-col(10);
          margin: $grid-gutter-width * 5 auto 0 auto;
        }
      }

      &.ce-frame-layout__column-programm,
      &.ce-frame-layout__column-session__heading {
        @include media-breakpoint-up(md) {
          @include make-col(10);
        }
      }

      .ce-frame-type-events_speakerlist &,
      &.ce-frame-layout__column-session__speaker {
        @include media-breakpoint-up(md) {
          @include make-col(12);
        }
      }
    }
  }

  &-type-list-registration_pi1 {
    .ce-frame-layout__container {
      @include media-breakpoint-only(xxl) {
        @include make-col(8);
      }
    }
  }

  &-type-theme_livestream {
    margin-top: 0;
    margin-bottom: 0;
  }
}
