/**
 * opacity responsive utility
 *
 * e.g.:
 * <p class="opacity-0 opacity-lg-1"></p>
 */
@each $breakpoint, $size in $grid-breakpoints {
  @if ($breakpoint == "xs") {
    @each $opacity in (0, 1) {
      .opacity-#{$opacity} {
        opacity: $opacity;
      }
    }
  } @else {
    @include media-breakpoint-up($breakpoint) {
      @each $opacity in (0, 1) {
        .opacity-#{$breakpoint}-#{$opacity} {
          opacity: $opacity;
        }
      }
    }
  }
}
