.ce-frame-type-theme_hero_text {
  margin-bottom: 0;
  margin-top: 0;

  // header height + X gutter from figma layout
  padding-top: __get($heights, "header-wrap.global") + ($grid-gutter-width) +
    59px;

  @include media-breakpoint-up(md) {
    padding-top: __get($heights, "header-wrap.global") +
      ($grid-gutter-width * 4);
  }

  @include media-breakpoint-up(lg) {
    padding-top: __get($heights, "header-wrap.global") +
      ($grid-gutter-width * 6);
  }

  @include media-breakpoint-up(ultrawide) {
    padding-top: __get($heights, "header-wrap.global") +
      ($grid-gutter-width * 8);
  }

  @include media-breakpoint-only(xxl) {
    max-width: none;
  }

  & > div {
    header {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(md) {
        margin-bottom: $grid-gutter-width * 2;
      }

      h1 {
        @include media-breakpoint-up(sm) {
          font-size: 2.75rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 5.75rem;
        }

        color: __get($theme-colors, "primary");
      }
    }
  }
}
