* {
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=6f65e425-8249-4608-bdb5-cf4bce485370");

// regular
@font-face {
  font-family: "HelveticaNeueW02-55Roma";
  src: url("#{$font-path}/b4cd926c-1a31-4785-b261-1db1a68c521e.woff2")
      format("woff2"),
    url("#{$font-path}/d5af76d8-a90b-4527-b3a3-182207cc3250.woff")
      format("woff");
  font-display: swap;
}

// regular italic
@font-face {
  font-family: "HelveticaNeueW02-56Ital";
  src: url("#{$font-path}/012263ed-c698-4d70-8e2b-8fa69531ecd3.woff2")
      format("woff2"),
    url("#{$font-path}/47a4e844-a7b2-4195-a8dd-8b281e43aa83.woff")
      format("woff");
  font-display: swap;
}

// medium
@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  src: url("#{$font-path}/9f93e286-eeaa-4077-b0b2-0e6901920f74.woff2")
      format("woff2"),
    url("#{$font-path}/5b4a262e-3342-44e2-8ad7-719998a68134.woff")
      format("woff");
  font-display: swap;
}

// medium italic
@font-face {
  font-family: "HelveticaNeueW02-66Medi";
  src: url("#{$font-path}/3899d137-0414-4371-b04e-c304065f9b71.woff2")
      format("woff2"),
    url("#{$font-path}/bbbd5ad3-15e0-455a-9b88-4aefc72b0562.woff")
      format("woff");
  font-display: swap;
}

// bold
@font-face {
  font-family: "HelveticaNeueW02-75Bold";
  src: url("#{$font-path}/2a5cf81d-ffbe-4c76-8982-270592fb36b0.woff2")
      format("woff2"),
    url("#{$font-path}/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff")
      format("woff");
  font-display: swap;
}

// bold italic
@font-face {
  font-family: "HelveticaNeueW02-76Bold";
  src: url("#{$font-path}/a8b57eaa-1a9e-4acf-966f-6b793fe5a1de.woff2")
      format("woff2"),
    url("#{$font-path}/41f91d0c-88d0-4d14-8d12-e40697e981b8.woff")
      format("woff");
  font-display: swap;
}
