.pulled-quote {
  @include media-breakpoint-up(ultrawide) {
    // 1920 bildschirm breite - container breite / 2 (wegen 6col) plus halbe gutter breite = perfekter negativ margin
    margin-right: -#{(
        (
            map-get($grid-breakpoints, "ultrawide") -
              map-get($container-max-widths, "xxl")
          ) / 2
      ) + ($grid-gutter-width / 2) - $grid-gutter-width / 2};

    .ce-frame-imageorient-26 & {
      margin-right: 0;
      margin-left: -#{(
          (
              map-get($grid-breakpoints, "ultrawide") -
                map-get($container-max-widths, "xxl")
            ) / 2
        ) + ($grid-gutter-width / 2) - $grid-gutter-width / 2};
    }
  }
}
