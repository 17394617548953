.select {
  &-wrapper {
    position: relative;
    border-bottom: 1px solid __get($theme-colors, "primary");
    margin-bottom: $grid-gutter-width;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: $grid-gutter-width / 2;
      width: 20px;
      height: 20px;
      border-left: 1px solid __get($theme-colors, "primary");
      border-bottom: 1px solid __get($theme-colors, "primary");
      transform: rotate(-45deg);
      pointer-events: none;
    }
  }
}
