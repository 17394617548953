.needs-validation {
  .form-control {
    &:valid {
      & + label em {
        opacity: 0;
      }
    }
  }

  .invalid-feedback {
    position: absolute;
    bottom: -19px;
    margin: 0;

    span {
      background: __get($theme-colors, "form-invalid-bg");
      color: __get($theme-colors, "form-invalid-text");
      padding: ($grid-gutter-width / 8) ($grid-gutter-width / 4);
    }

    @at-root [type="checkbox"] ~ .invalid-feedback {
      padding-left: $grid-gutter-width * 1.5;
    }
  }

  &.was-validated {
    .form-control {
      &:valid {
        background: none;
        border-color: __get($theme-colors, "danger");
        padding: 0;

        & + label em {
          opacity: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &:invalid {
        &:focus {
          box-shadow: none;
        }

        & + label em {
          color: __get($theme-colors, "danger");
        }
      }

      &[type="checkbox"] {
        &:invalid {
          & ~ .invalid-feedback {
            padding-left: $grid-gutter-width * 1.5;
          }
        }
      }
    }
  }

  .alert {
    ul {
      list-style: initial;
      margin: 0 0 0 $grid-gutter-width / 2;

      li {
        margin-bottom: $grid-gutter-width / 16;

        a {
          text-transform: none;
          color: inherit;
        }
      }
    }
  }
}
