:root {
  --tooptip-width: 310px;
  --tooltip-height: 68px;
}

.tooltip {
  position: relative;

  &:after,
  &:before {
    transition: all 0.2s ease-in-out;
    transform: translateY(-10px);
    opacity: 0;
  }

  &:hover:after,
  &:hover:before {
    transform: translateY(0);
    opacity: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: calc(var(--tooltip-height) + var(--tooltip-height) * -1.42);
    left: 50%;
    margin-left: -15px;
    width: 0;
    height: 0;
    border-width: 15px 15px 0 15px;
    border-color: #000000 transparent transparent transparent;
    border-style: solid;
  }

  &:after {
    content: attr(data-tooltip);
    position: absolute;
    top: calc(var(--tooltip-height) * -1.45);
    left: 70%;
    width: var(--tooptip-width);
    height: auto;
    background: $black;
    color: $white;
    pointer-events: none;
    font-size: 14px;
    line-height: 1.3;
    margin-left: calc(var(--tooptip-width) / -2);
    padding: 8px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    z-index: 999;
  }
}
