.ce-frame-type-theme_notes_teaser {
  background: __get($theme-colors, "info");
  min-height: 74px;
  padding: $grid-gutter-width / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  @include media-breakpoint-only(xxl) {
    max-width: none;
  }

  p,
  a {
    margin: 0;
    line-height: 1.2;
    font-size: 14px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      margin: 0 $grid-gutter-width / 4;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 $grid-gutter-width / 2;
    }
  }

  a {
    text-decoration: underline;
    text-transform: uppercase;
    margin-top: $grid-gutter-width / 4;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}
