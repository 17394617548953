label {
  input + &,
  .form-check & {
    color: __get($theme-colors, "primary");
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
}
