form {
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter-width / 2;

    label {
      opacity: 1;
      order: 0;
      transition: all 0.2s ease-in-out;
    }

    input {
      &:placeholder-shown {
        & + label {
          opacity: 0;
          transform: translateY(52px);
        }
      }

      &[type="text"],
      &[type="email"],
      &[type="number"] {
        order: 1;
      }
    }
  }

  button {
    margin-top: 25px;
    text-transform: lowercase;
  }
}
