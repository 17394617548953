.grid {
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  &-layer-1 {
    grid-column: 1/1;
    grid-row: 1/2;
  }

  &-layer-2 {
    grid-column: 1/1;
    grid-row: 2;

    @include media-breakpoint-up(lg) {
      grid-row: 1/2;
    }

    .ce-frame-imageorient-25 &,
    .ce-frame-imageorient-26 & {
      grid-row: 2;
    }
  }
}
