a {
  @include focus;

  .ce-frame & {
    text-decoration: underline;

    &.link {
      text-transform: none;
    }
  }

  .ce-frame-type-theme_text_media & {
    text-transform: none;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
