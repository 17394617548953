.ce-frame-type-theme_hero {
  @include css-grid();

  color: #fff;
  margin-bottom: 0;
  margin-top: 0;

  @include media-breakpoint-only(xxl) {
    max-width: none;
  }

  & > div {
    @include css-grid("layer-1");
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--hero-overlay);
      opacity: var(--hero-overlay-opacity);
    }

    .hero-top {
      h1 {
        font-size: 28px;
        line-height: 1.25;
        text-transform: uppercase;
        margin-bottom: $grid-gutter-width / 2;

        @include media-breakpoint-up(sm) {
          font-size: 45px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: $grid-gutter-width;
          font-size: 45px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 55px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 65px;
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: 80px;
        }
      }

      h2 {
        font-size: 20px;
        margin-bottom: $grid-gutter-width / 2;

        @include media-breakpoint-up(sm) {
          font-size: 22px;
          margin-bottom: $grid-gutter-width / 2;
        }

        @include media-breakpoint-up(lg) {
          font-size: 28px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 36px;
          margin-bottom: $grid-gutter-width;
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: 45px;
        }
      }

      .btn {
        text-decoration: none;
        text-transform: none;
        width: 100%;
        justify-content: center;

        @include media-breakpoint-up(md) {
          line-height: 1;
          width: auto;
          margin-top: 0;

          & + .btn {
            margin-left: $grid-gutter-width * 0.5;
          }
        }

        @include media-breakpoint-up(lg) {
          line-height: 1;
          width: auto;
        }

        @include media-breakpoint-up(xxl) {
          & + .btn {
            margin-left: $grid-gutter-width / 2;
            margin-top: 0;
          }
        }
      }

      *:last-child {
        margin-bottom: 0;
      }
    }

    .hero-bottom {
      @include media-breakpoint-up(md) {
        margin-top: $grid-gutter-width * 2;
      }

      @include media-breakpoint-up(ultrawide) {
        margin-top: $grid-gutter-width * 4;
      }

      h3 {
        font-size: 18px;
        margin-bottom: $grid-gutter-width / 4;

        @include media-breakpoint-up(sm) {
          margin-bottom: $grid-gutter-width / 2;
          font-size: 16px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: $grid-gutter-width / 2;
          font-size: 18px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 22px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: $grid-gutter-width;
          font-size: 28px;
        }
      }

      img {
        max-height: $grid-gutter-width;
        height: auto;
      }
    }
  }

  & > figure,
  & > .placeholder {
    @include css-grid("layer-2");

    margin: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@include ie11-only-wrap-shizzle(
  (
    ".ce-frame-type-theme_hero > div",
    ".ce-frame-type-theme_hero > figure",
    ".ce-frame-type-theme_hero > .placeholder"
  )
) {
  width: calc(100vw - #{$grid-gutter-width / 2});
}
