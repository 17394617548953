figure {
  margin-bottom: 0;

  &.image-zoom {
    overflow: hidden;

    img {
      transform: scale(1.1);
      transition: all 0.5s ease-in-out;
    }
  }
}
