/* used for event speaker teaser and page teaser */

.teaser {
  &-list {
    @include make-row();

    width: 100%;

    &__item {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      margin-bottom: $grid-gutter-width;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: $grid-gutter-width / 2;
        right: $grid-gutter-width / 2;
        bottom: 0;
        background: rgba(__get($theme-colors, "teaser-overlay"), 0.5);

        z-index: 1;
        transition: all 0.4s ease-in-out;
      }

      &:hover:before {
        background: rgba(__get($theme-colors, "teaser-overlay"), 0);
      }

      a {
        @include css-grid();
        text-decoration: none;
        color: __get($theme-colors, "teaser-link-color");
        text-transform: none;
        position: relative;
        overflow: hidden;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(__get($theme-colors, "primary"), 0.4);

          z-index: 0;
          transform: translateY(100%);
          transition: all 0.4s ease-in-out;
          border-radius: 95% 35% 0 0;
        }

        &:hover {
          color: __get($theme-colors, "teaser-link-color");

          &:after {
            transform: translateY(0);
            border-radius: 0;
          }

          .teaser-list__info {
            header {
              transform: translateY($grid-gutter-width / 2);
            }

            .teaser-list__description {
              transform: translateY(-$grid-gutter-width / 4);
            }
          }
        }
      }
    }

    &__info {
      @include css-grid("layer-1");
      padding: $grid-gutter-width;
      position: relative;
      z-index: 2;

      @include media-breakpoint-up(md) {
        padding: $grid-gutter-width / 2;
      }

      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
      }

      @include media-breakpoint-up(xl) {
        padding: $grid-gutter-width;
      }

      @include media-breakpoint-up(xxl) {
        padding: $grid-gutter-width * 1.5 $grid-gutter-width;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      header {
        transform: translateY(0);
        transition: all 0.6s ease;

        h3 {
          @include headings("h3");
          color: #fff;
          hyphens: auto;
        }
      }
    }

    &__image {
      @include css-grid("layer-2");
      margin: 0;
    }

    &__subheader {
      position: relative;
      margin-top: $grid-gutter-width * 0.75;

      time,
      span {
        text-transform: none;
        font-size: 16px;
      }
    }

    &__description {
      transform: translateY(0);
      transition: all 1s ease;
      font-size: 16px;
      line-height: 1.5;

      @include media-breakpoint-up(xxl) {
        font-size: 20px;
        line-height: 1.7;
      }
    }
  }
}

@include ie11-only-wrap-shizzle(".teaser-list__info a") {
  display: block;
}

@include ie11-only-wrap-shizzle(".teaser-list__info") {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@include ie11-only-wrap-shizzle(".teaser-list__image") {
  @include media-breakpoint-up(md) {
    width: calc(35vw - #{$grid-gutter-width / 2});
  }

  @include media-breakpoint-up(lg) {
    width: calc(25.5vw - #{$grid-gutter-width / 2});
  }
}
