@mixin bodytext() {
  .bodytext {
    font-size: 16px;
    line-height: 1.6;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 1.75;
      text-align: left;
    }

    @include media-breakpoint-up(xl) {
      font-size: 20px;
    }
  }
}
