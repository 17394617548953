input {
  &[type="text"],
  &[type="email"],
  &[type="number"] {
    padding: $grid-gutter-width / 2 0;
    font-size: 24px;
    border-bottom: 1px solid __get($theme-colors, "primary");

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 35px;
    }

    &:focus {
      border-bottom-width: 3px;
      border-bottom-color: __get($theme-colors, "primary");
    }
  }

  &[type="number"],
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }

    font-weight: $font-weight-normal;
    letter-spacing: 1px;
  }
}

@include ie11-only-wrap-shizzle('input[type="text"]') {
  padding: 0px;
}
