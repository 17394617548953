/**
  Wrapper
*/
.image-slider {
  position: relative;
  width: 100%;

  @include media-breakpoint-up(ultrawide) {
    // 1920 bildschirm breite - container breite / 2 (wegen 6col) plus halbe gutter breite = perfekter negativ margin
    width: calc(
      100% + #{(
          (
              map-get($grid-breakpoints, "ultrawide") -
                map-get($container-max-widths, "xxl")
            ) / 2
        ) + ($grid-gutter-width / 2) - $grid-gutter-width / 2}
    );
  }

  &__container {
    position: relative;
  }

  &__figure {
    margin-bottom: 0;
  }

  &__figcaption {
    font-size: 14px;
  }

  .splide {
    &__list {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      aspect-ratio: 467 / 356;

      @include media-breakpoint-up(lg) {
        aspect-ratio: 467 / 376;
      }

      @include media-breakpoint-up(xl) {
        aspect-ratio: 467 / 336;
      }
    }

    &__pagination {
      position: static;
      top: unset;
      left: unset;
      bottom: unset;
      transform: none;
      flex-wrap: nowrap;
      width: 100%;
      min-height: 46px;
    }
  }

  .previous-button,
  .next-button {
    color: $primary;
  }

  .previous-button {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -15px;
  }

  .next-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -15px;
  }

  .previous-button,
  .next-button {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    line-height: 1;
  }
}

.lightbox {
  display: block;
}

/**
  Controls - pause/resume, previous, and next buttons
*/

/* Resets for all buttons */
.is-control {
  z-index: 5;
  background: none;
  color: rgba(0, 0, 0, 0.7);
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear;

  &__svg-wrapper {
    border: 1px solid transparent;
    border-radius: 100%;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 15px;
    width: 15px;
  }

  &:focus {
    outline: none;
  }
}

/**
  Single slide
*/
.splide__slide {
  position: relative;
  height: auto;
  visibility: hidden;
  display: flex;
  flex-direction: column;
}

.splide__slide.is-visible {
  visibility: visible;
}

/**
  Restyle the slide dots generated by Slick Slider
*/
.splide__pagination {
  bottom: -35px;
}

.splide__pagination {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li + li {
    margin-left: 10px;
  }
}

/* Create a circle to serve as the slide dot */
.splide__pagination {
  &__page {
    &.is-active {
      opacity: 1;
      transform: scale(1);
    }
  }

  li button {
    position: relative;
    border-radius: 0;
    background-color: transparent;
    width: 15px;
    height: 15px;
    margin: 0;
    padding: 0;
    border: 0;

    &::before {
      content: "";
      display: block;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border: 1px solid $primary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(1) translate(-50%, -50%);
    }
  }
}

/* Give the focused slide dot a nice visible focus indicator */
.splide__pagination li button:focus,
.is-control:focus .is-control__svg-wrapper {
  border-radius: 100%;
  //box-shadow: 0 0 0 1px $light, 0 0 0 4px $primary;
  //outline: none;
}

/* Leverage aria-current for styling to ensure we're using the attribute correctly */
.splide__pagination li button[aria-current="true"] {
  &::before {
    background-color: $primary;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
