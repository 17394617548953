.ce-frame-type-theme_livestream {
  background: __get($theme-colors, "livestream");
  @include media-breakpoint-only(xxl) {
    max-width: 100%;
  }

  p {
    font-family: $font-family-medium;

    span {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;

      & + & {
        font-family: $font-family-base;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
  }
}
