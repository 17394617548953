.ce-frame-type-theme_blog_overview {
  .ce-frame-layout__column.ce-frame-layout__column-blog_overview {
    @extend .col-12, .col-ultrawide-10, .mx-auto;

    #blog-list.articles {
      max-width: none;
      margin: 0 -16px;
      padding: 0;

      li {
        padding: 0 16px;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 50%;
        }

        @include media-breakpoint-up(xl) {
          width: percentage(4 / 12);
        }

        .articles__item {
          width: 100%;
        }
      }
    }
  }
}
