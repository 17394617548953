[data-dce] > .text {
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }

  @include media-breakpoint-up(xl) {
    @include make-col(9);
    @include make-col-offset(1);
  }

  .frame-layout-1 & {
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }

    @include media-breakpoint-up(ultrawide) {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  margin-top: 0;

  p {
    &:not([class]) {
      max-width: none;
    }
  }

  .ce-accordion .collapse & {
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
  }
}
