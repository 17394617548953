.speaker {
  &__header {
    display: flex;
    flex-direction: column;

    h2,
    h3 {
      font-size: 1.625rem;

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 3.75rem;
      }
    }
  }

  &__subheader {
    order: -1;
    color: $primary;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }
  }

  &__heading {
    font-family: $font-family-medium;
    line-height: 1.25;
    margin-top: $grid-gutter-width / 4;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }

    .speaker-detail & {
      color: __get($theme-colors, "primary");
      font-size: 2.375rem;

      @include media-breakpoint-up(md) {
        font-size: 2.812rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 5rem;
      }
    }
  }

  &__subheading {
    line-height: 1.2;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }

  &__company {
    font-family: $font-family-base;
    line-height: 2.125;
    font-size: 1rem;
    font-weight: normal;
    hyphens: none;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
  }

  &__info {
    position: relative;
    flex-grow: 1;
  }

  &__list {
    &-item {
      &:hover {
        .image-zoom img {
          transform: scale(1);
        }
      }
    }
  }

  &__teaser {
    color: __get($theme-colors, "primary");
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.875rem;
    }
  }

  &__image {
    figcaption {
      font-size: 0.975rem;
      color: __get($theme-colors, "gray");
      padding: 0.5rem 0;
      text-align: center;
    }
  }

  &__bodytext {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }

  &__detail-link {
    display: inline-block;
    margin-top: $grid-gutter-width / 2;
    font-size: 16px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 20px;
    }
  }

  &-session {
    &__time {
      display: block;
      font-size: 0.875rem;
      line-height: 1.75;
      margin-bottom: $grid-gutter-width / 4;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }

    &__topic {
      display: block;
      color: __get($theme-colors, "primary");
      line-height: 1.5;
      font-size: 1.25rem;
      margin-bottom: $grid-gutter-width / 2;

      @include media-breakpoint-up(md) {
        font-size: 1.375rem;
      }
    }

    &__teaser {
      display: block;
      line-height: 1.5;
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
      }
    }
  }

  &-pagination {
    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 25px;
      left: $grid-gutter-width / 2;

      .lightbox__next-title,
      .lightbox__prev-title {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }

  &__filter {
    margin: $grid-gutter-width ($grid-gutter-width / -2) $grid-gutter-width * 2
      ($grid-gutter-width / -2);

    &-item {
      padding: ($grid-gutter-width / 4) ($grid-gutter-width / 2);

      &:hover button:after {
        opacity: 1;
      }
    }

    .active {
      font-family: $font-family-bold;

      &:after {
        opacity: 1;
      }
    }

    button {
      border: none;
      background: none;
      font-size: 1.125rem;
      padding: 0;
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: $grid-gutter-width / -4;
        background: __get($theme-colors, "copytext");
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }

    &-select {
      color: __get($theme-colors, "primary");
      appearance: none;
      font-size: 1.25rem;

      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }

      border: none;
      width: 100%;
      margin-bottom: $grid-gutter-width / 4;
      background: transparent;
    }
  }
}

.clickarea {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
