iframe {
  &#player {
    @extend .aspect-ratio-xs-item;
    // need to scale the video element a bit to
    // hide the video title.
    transform: scale(1.2);
  }

  &.cookieconsent-optin-marketing {
    &[data-src] {
      display: none;
    }

    &[src] {
      & + picture {
        display: none;
      }
    }
  }

  .lity-content & {
    width: 80vw;
    aspect-ratio: 16/9;
  }
}
