.animate {
  &-opacity {
    transition: opacity 1s ease-in-out;

    &-0 {
      opacity: 0;
    }

    &-1 {
      opacity: 1;
    }
  }
}
