.wiffme {
  &-optin {
    background: $info;
    width: 100%;
    padding: $grid-gutter-width;
    text-align: center;
  }

  &-map {
    width: 100%;
    height: 600px;
    margin: 0 auto;
  }
}
