#hero-placeholder {
  & + .ce-frame {
    margin-top: 0;

    &-type-theme_text_media {
      margin-top: __get($spacers, "dg");
    }

    &-type-list-bbboilerplate_pi4 {
      margin-top: $grid-gutter-width * 2;

      @include media-breakpoint-up(md) {
        margin-top: __get($spacers, "big");
      }

      @include media-breakpoint-up(xl) {
        margin-top: __get($spacers, "big") + ($grid-gutter-width * 3);
      }
    }
  }
}
