.form-check {
  padding: 0;

  input {
    position: absolute;
    opacity: 0;

    &:checked {
      & + label {
        &:after {
          background: __get($theme-colors, "primary");
        }

        &:before {
          content: "";
        }
      }
    }

    &:focus + label {
      &:after {
        box-shadow: 1px 1px 4px __get($theme-colors, "checkbox-focus");
      }
    }
  }

  label {
    position: relative;
    padding-left: $grid-gutter-width * 1.5;
    color: __get($theme-colors, "copytext");

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: $grid-gutter-width;
      height: $grid-gutter-width;
      border: 1px solid __get($theme-colors, "primary");
      border-radius: $border-radius;
    }

    &:before {
      position: absolute;
      left: 12px;
      top: 4px;
      width: 8px;
      height: 18px;
      border-right: 3px solid __get($theme-colors, "checkbox-checked");
      border-bottom: 3px solid __get($theme-colors, "checkbox-checked");
      transform: rotate(45deg);
      z-index: 1;
    }
  }
}
