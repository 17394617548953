// load all variables
@import "breakpoints";
@import "container";
@import "aspect-ratios";

// grid settings
$grid-gutter-width: 32px;
$navigation-gutter: 24px;
$ce-wrapper-max-width: (
  xxl: 1440px,
  ultrawide: 1900px,
);

// bootstrap overrides
$headings-margin-bottom: 0;

$font-family-sans-serif: "HelveticaNeueW02-55Roma", Helvetica, Arial, sans-serif;
$font-family-monospace: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;
$font-family-italic: "HelveticaNeueW02-56Ital", Helvetica, Arial, sans-serif;
$font-family-medium: "HelveticaNeueW02-65Medi", Helvetica, Arial, sans-serif;
$font-family-medium-italic: "HelveticaNeueW02-66Medi", Helvetica, Arial,
  sans-serif;
$font-family-bold: "HelveticaNeueW02-75Bold", Helvetica, Arial, sans-serif;
$font-family-bold-italic: "HelveticaNeueW02-76Bold", Helvetica, Arial,
  sans-serif;
$font-weight-normal: 400;

$btn-padding-y: $grid-gutter-width / 2;
$btn-padding-x: $grid-gutter-width / 2;

$border-color: #c4c4c4;

// custom variables
$base-font-size: 16px;
$font-path: "../Fonts";

$font-settings: (
  default: (
    global: (
      body: (
        font-size: 16px,
      ),
      "h1, h2, h3, h4, h5": (
        font-family: $font-family-bold,
      ),
      "h2, h3": (
        margin-top: $grid-gutter-width * 0.5,
        margin-bottom: $grid-gutter-width,
      ),
      "h4, h5": (
        margin-top: $grid-gutter-width * 0.5,
        margin-bottom: $grid-gutter-width * 0.25,
      ),
      "b, strong": (
        font-family: $font-family-medium,
      ),
      "i, em": (
        font-family: $font-family-italic,
      ),
      "i b, i strong, b i, strong i, em b, em strong, b em, strong em": (
        font-family: $font-family-medium-italic,
      ),
    ),
    text: (
      ".teasertext": (
        font-size: 18px,
        line-height: 1.5,
      ),
      "h3 time": (
        font-size: 24px,
      ),
    ),
  ),
  md: (
    text: (
      ".teasertext": (
        font-size: 20px,
        line-height: 1.6,
      ),
      "h3 time": (
        font-size: 26px,
      ),
    ),
  ),
  xxl: (
    text: (
      ".teasertext": (
        font-size: 30px,
      ),
      "h2, h3": (
        margin-top: $grid-gutter-width * 1.5,
        margin-bottom: $grid-gutter-width,
      ),
      "h4, h5": (
        margin-top: $grid-gutter-width,
        margin-bottom: $grid-gutter-width * 0.5,
      ),
      "h3 time": (
        font-size: inherit,
      ),
    ),
  ),
);

// colors
$gray-100: #565656;
$black: #000;
$white: #fff;
$primary: #e0001a;
$light: $white;
$info: #ecece6;
$beige: #fbfbf6;
$secondary: $beige;
$danger: $primary;
$overlay: #4e091d;
$link-hover-color: $primary;
$checkbox-focus: rgba(220, 0, 0, 0.6);
$lightblue: #b4d5d5;
$gallery-icon-bg: rgba(0, 0, 0, 0.3);
$gallery-icon-color: rgba(255, 255, 255, 0.8);

// button stuff
$btn-transition-icon: background 0.15s ease-in-out;

$theme-colors: (
  primary: $primary,
  copytext: $black,
  logo-init-color: $black,
  navigation-init-color: $black,
  navigation-background: $light,
  form-background-beige: $beige,
  divider: $black,
  footer-background: $light,
  form-invalid-bg: $primary,
  form-invalid-text: $white,
  checkbox-checked: $white,
  event-hero-heading: $white,
  subnavigation-color: $white,
  subnavigation-color-separator: $white,
  subnavigation-color-main: $white,
  nav-light: $white,
  header-background: $white,
  teaser-link-color: $white,
  teaser-overlay: $overlay,
  "gray": $gray-100,
  checkbox-focus: $checkbox-focus,
  livestream: rgba($lightblue, 0.48),
  "white": $white,
  svg-bg-color: $gallery-icon-bg,
);

// define heights
// use id selectors as array key and breakpoints for sub-keys
// global is the smallest breakpoint
$heights: (
  header-wrap: (
    global: 94px,
    lg: 120px,
  ),
  footer: (
    global: auto,
    md: $grid-gutter-width * 5,
  ),
  hero-placeholder: (
    global: 72px,
    lg: 98px,
  ),
);

// spacers for margin and padding spaces
// use classnames like px-fg or px-lg-fg
// for full gutter padding on x-axis or
// full gutter padding lg or bigger on x-axis
$spacers: (
  vsg: $grid-gutter-width * 0.125,
  sg: $grid-gutter-width * 0.25,
  qg: $grid-gutter-width / 4,
  hg: $grid-gutter-width / 2,
  fg: $grid-gutter-width,
  1h: $grid-gutter-width * 1.5,
  dg: $grid-gutter-width * 2,
  medium: $grid-gutter-width * 3,
  almost-big: $grid-gutter-width * 4,
  big: $grid-gutter-width * 5,
  bigger: $grid-gutter-width * 7.5,
  ultra: $grid-gutter-width * 10,
);

// animation settings
$default-animation-style: 0.2s ease-in-out;

// Form Settings
$label-margin-bottom: 0;
$input-border-radius: 0;
$input-line-height: 1;
$input-font-weight: $font-weight-normal;
$input-bg: transparent;
$input-color: $black;
$input-border-width: 0;
$input-focus-box-shadow: none;
$input-placeholder-color: $primary;

// other settings
$nav-tabs-height: 46px;
$scrollbar-height: 14px;

// gallery icons
$icon-plus: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 24.334L13.1876 0.521595M24.8124 12.1464L1 12.334' stroke='white' stroke-opacity='0.8' stroke-width='2'/%3E%3C/svg%3E%0A";
$icon-download: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 24.334L13.1876 0.521595M24.8124 12.1464L1 12.334' stroke='white' stroke-opacity='0.8' stroke-width='2'/%3E%3C/svg%3E%0A";

@import "css-variable-colors";
