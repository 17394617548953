.combo {
  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(ultrawide) {
    @include make-col(9);
    @include make-col-offset(1);
  }

  &__image {
    flex: 1 0 calc(40% - #{$grid-gutter-width / 2});
    width: auto;
    margin-right: $grid-gutter-width / 2;
  }

  &__text {
    padding: 0;
    flex: 1 0 calc(60% - #{$grid-gutter-width / 2});
    flex-direction: column;
    margin-left: $grid-gutter-width / 2;

    &.text {
      p {
        max-width: none;
      }
    }
  }
}
