body {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &.no-scroll {
    --navigation-init-color: #{__get($theme-colors, "navigation-init-color")};
    overflow: hidden;
  }
}
