.tmpl-Event {
  .hero-top {
    time {
      font-size: 20px;

      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 28px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 36px;
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: 45px;
      }
    }

    .event-info {
      font-size: 16px;
      line-height: 1.625;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 1.75;
      }
    }
  }
}
