.ce-accordion {
  & > div {
    @include make-row();

    .accordion {
      @include make-col-ready();
      @include make-col(12);

      .collapse-item {
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(md) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .frame {
        @include make-row();
      }
    }
  }
}
