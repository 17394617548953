body > .three-col-grid {
  @extend .container-fluid;
  max-width: none;

  & > .row {
    @include make-row();

    & > .col-md-4 {
      width: auto;
    }
  }

  @include media-breakpoint-up(ultrawide) {
    max-width: 1425px;
  }
}
