@use "sass:math";

$supi-breakpoint: map-get($grid-breakpoints, "md");

$supi-colors: (
  primary: $body-color,
  success: $primary,

  warning: $secondary,
  notice: $primary,
  button-wrap-bgcolor: $gray-100,
);

@import "../../../../vendor/supseven/supi/Build/Src/Scss/Supi";

:root {
  --supi-color-dismiss: #{$info};
  --supi-color-dismiss-background: #{$primary};
  --supi-font-size-head: 1.5rem;
  --supi-font-size: 1rem;
  --supi-banner-box-shadow: #{$info};
  --supi-color-choose-invert: #{$white};
  --supi-button-wrap-bgcolor: #{$beige};
}

.tx-supi {
  &__block-item__text {
    padding: $grid-gutter-width / 8 0 $grid-gutter-width / 8 $grid-gutter-width *
      0.5;
  }

  &__block-item__table {
    padding-top: $grid-gutter-width / 4;
    padding-bottom: $grid-gutter-width / 4;
  }

  &.theme-default {
    p {
      margin-bottom: $grid-gutter-width / 2;

      &:last-child {
        margin: 0;
      }
    }
  }

  h3,
  .h3 {
    margin-bottom: $grid-gutter-width / 2;
  }

  &__youtube-text {
    margin: 0;
    position: relative;
    background: $beige;

    @include media-breakpoint-up(sm) {
      position: absolute;
      background: rgba($beige, 0.9);
      top: 50%;
      transform: translateY(-50%);
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      background: rgba($beige, 0.9);
    }
  }

  &__youtubecontainer {
    position: relative;
    overflow: hidden;
    width: 100%;

    &::after {
      content: "";
      display: block;
      padding-top: 56.25%; //For 16:9 Videos
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__youtube {
    position: absolute;
    inset: 0;
  }
}

.supi-service {
  background: $beige;
  padding: $grid-gutter-width;
  text-align: center;
  margin: $grid-gutter-width 0;

  p {
    text-align: center;
  }
}

.lity-content {
  .tx-supi__youtube,
  .tx-supi__youtube-text {
    position: relative;
    inset: auto;
  }

  .tx-supi__youtube-text {
    width: 400px;
    aspect-ratio: 16/9;
  }
}
