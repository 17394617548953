.ce-frame-type-list-bbboilerplate_pi1 {
  margin-top: 200px !important;

  @extend .border-top;
  background-color: __get($theme-colors, "secondary");

  .ce-frame-layout__container + .ce-frame-layout__container {
    margin-top: $grid-gutter-width * 2;

    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width * 3;
    }
  }

  .main-nav-tabs {
    .select-holder {
      margin-top: -5px + -$grid-gutter-width * 2;
    }

    .nav-listitem {
      .nav-item {
        padding-left: 0;
        padding-right: 0;
        margin-right: $grid-gutter-width;
        white-space: nowrap;
      }

      &:last-of-type {
        .nav-item {
          margin-right: 0;
        }
      }
    }
  }

  .days-nav-tabs {
    .nav-listitem {
      width: 50%;
      padding: 0 $grid-gutter-width / 4;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }

  .days-nav-tabs.days-nav-tabs {
    height: auto;
    flex-wrap: wrap;
    padding: 4px 0 $scrollbar-height 0;
    margin: 0 $grid-gutter-width / 4 * -1;
    row-gap: $grid-gutter-width / 2;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    .nav-item {
      white-space: nowrap;
      width: 100%;
    }
  }

  @include media-breakpoint-only(lg) {
    .main-nav-tabs {
      .nav-tabs {
        flex-wrap: nowrap;

        .nav-item {
          flex: 1 0 auto;
        }
      }
    }
  }

  [role="tablist"] .btn {
    text-transform: none;
    text-decoration: none;
    @include medium;
    font-size: 20px;
    line-height: 1.75;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 1.4;
    }

    &:hover {
      background: $primary;
      border-color: $primary;
      color: $white;
    }

    &.active {
      background: none;
      border-color: $primary;
      color: $primary;

      &:hover {
        background: $primary;
        border-color: $primary;
        color: $white;
      }

      &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($primary, 0.5);
      }
    }
  }

  .tab-content .tab-content {
    overflow: hidden;
  }

  .programm-overview {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      margin-left: 0 -$grid-gutter-width;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
    }

    &.full-width {
      margin-left: 0;

      .programm-overview__room {
        padding: 0;
        flex-basis: 100%;

        & > ul {
          max-width: none;

          & > li {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
              flex-direction: row;
            }

            time {
              margin-top: 1px;
              flex: 0 0 auto;

              @include media-breakpoint-up(lg) {
                flex-basis: 200px;
              }

              @include media-breakpoint-up(xl) {
                flex-basis: 250px;
              }
            }

            & > div {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
            }
          }
        }
      }
    }

    &__room {
      flex: 1 0 100%;

      @include media-breakpoint-up(md) {
        flex-basis: 45%;
        row-gap: $grid-gutter-width;
      }

      @include media-breakpoint-up(lg) {
        scroll-snap-align: start;
        row-gap: 0;
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
      }

      @include media-breakpoint-up(xxl) {
        flex-basis: 30%;
      }

      li {
        margin-bottom: $grid-gutter-width;

        time {
          @include medium;
          font-size: 14px;
          line-height: 2;

          @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 1.75;
          }
        }

        a {
          @include medium;
          font-size: 18px;
          line-height: 1.5;

          @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 1.2;
          }

          @include media-breakpoint-up(xl) {
            font-size: 22px;
            line-height: 1.2;
          }

          text-transform: none;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ul.speaker-list {
        display: flex;
        max-width: none;
        flex-wrap: wrap;

        li {
          flex: 0 0 auto;
          margin: 0 5px 0 0;

          font-size: 16px;

          @include media-breakpoint-up(md) {
            font-size: 18px;
          }

          line-height: 1.8;

          &:after {
            position: relative;
            content: "; ";
          }

          &:last-child:after {
            display: none;
          }
        }
      }
    }
  }
}
