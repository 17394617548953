.centered {
  @include make-col(12);

  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }

  &__aligned {
    width: auto;
    height: auto;
    margin: auto;
    max-height: initial;
  }
}
