@mixin arrow($direction: "down", $color: "primary") {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: solid __get($theme-colors, $color);
  border-width: 0 3px 3px 0;
  padding: 3px;
  pointer-events: none;

  @if ($direction == "down") {
    transform: rotate(45deg);
  }

  @if ($direction == "right") {
    transform: rotate(-45deg);
  }

  @if ($direction == "up") {
    transform: rotate(-135deg);
  }

  @if ($direction == "left") {
    transform: rotate(135deg);
  }
}
