.icons {
  @include medium;
  font-size: 16px;

  &.arrow {
    &-left {
      padding-left: $grid-gutter-width;
      position: relative;

      &:before {
        @include arrow("left");
        left: 0;
        top: 2px;
      }
    }
  }
}
