/**
  use mixing for setting up font-size and line-height (or any other pixel based setting)
  defined in the font-settings array

  no special calls needed. this mixin is used in the base/font-settings file
 */
@mixin get-all-font-settings() {
  @each $breakpoint, $groups in $font-settings {
    @each $group, $elements in __get($font-settings, $breakpoint) {
      @each $element,
        $styles in __get($font-settings, "#{$breakpoint}""#{$group}")
      {
        #{$element} {
          @each $style,
            $value
              in __get($font-settings, "#{$breakpoint}""#{$group}""#{$element}")
          {
            @if $breakpoint == "default" {
              #{$style}: #{$value};
            } @else {
              @include media-breakpoint-up($breakpoint) {
                #{$style}: #{$value};
              }
            }
          }
        }
      }
    }
  }
}

@mixin regular() {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

@mixin italic() {
  font-family: $font-family-italic;
  font-weight: $font-weight-normal;
}

@mixin medium() {
  font-family: $font-family-medium;
  font-weight: $font-weight-normal;
}

@mixin bold() {
  font-family: $font-family-bold;
  font-weight: $font-weight-bold;
}
