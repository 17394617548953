// the .frame dce / typo3 default class to implement bootstrap library styles
body > .frame,
body > .ce-accordion {
  &:not(.frame-layout-2) {
    @extend .container-fluid;

    & > [data-dce] {
      @include make-row();
    }
  }
}
