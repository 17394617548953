/**
 * hyphens responsive utility
 *
 * e.g.:
 * <p class="hyphens-none opacity-lg-auto"></p>
 */
@each $breakpoint, $size in $grid-breakpoints {
  @if ($breakpoint == "xs") {
    @each $hyphens in (auto, none) {
      .hyphens-#{$hyphens} {
        hyphens: $hyphens;
      }
    }
  } @else {
    @include media-breakpoint-up($breakpoint) {
      @each $hyphens in (auto, none) {
        .hyphens-#{$breakpoint}-#{$hyphens} {
          hyphens: $hyphens;
        }
      }
    }
  }
}
