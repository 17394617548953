.nav-tab {
  .select-holder {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 70px;
      height: calc(100% - 1px);
      background: rgb(255, 255, 255);
      background: linear-gradient(
        86deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%
      );
      right: 0;
      top: 0;
      pointer-events: none;
    }

    &:after {
      @include arrow("down");
      right: $grid-gutter-width / 2;
      top: $grid-gutter-width / 4;
    }

    select {
      @include focus();

      font-size: 20px;
      line-height: 36px;
      @include medium;
      width: 100%;
      border: none;
      border-bottom: 1px solid __get($theme-colors, "primary");
      color: __get($theme-colors, "primary");
      background: none;
      appearance: none;
    }
  }

  .nav {
    &.nav-tabs {
      border-bottom: none;
      margin-top: -15px;
      height: $nav-tabs-height;

      .nav-item {
        &.nav-link {
          color: __get($theme-colors, "navigation-init-color");
          border: none;
          text-decoration: none;
          text-transform: none;
          font-size: 18px;
          line-height: 24.51px;
          height: 47px;

          &.active {
            color: __get($theme-colors, "primary");
            background-color: transparent;
            border-bottom: 1px solid __get($theme-colors, "primary");
            @include bold;
          }

          &:hover {
            color: __get($theme-colors, "primary");
            border-bottom: 1px solid __get($theme-colors, "primary");
          }
        }
      }
    }
  }
}
