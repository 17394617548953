.not-styled-yet {
  position: relative;

  &:after {
    content: "Not styled yet.";
    background: __get($theme-colors, "primary");
    color: #fff;
    font-size: 9px;
    padding: 5px;
    position: absolute;
    bottom: -10px;
    left: 0;
    text-transform: uppercase;
  }
}
