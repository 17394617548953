.event-teaser {
  &__item {
    margin-bottom: $grid-gutter-width * 1.25;
  }

  &__header {
  }

  &__heading {
    font-family: $font-family-bold;
    font-size: 1.875rem;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: 3.75rem;
    }
  }

  &__time {
    font-size: 1.25rem;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: 1.562rem;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: 1.875rem;
    }
  }

  &__link {
    &.event-teaser__link {
      text-decoration: none;
      text-transform: none;

      &:hover .link-decoration:before {
        right: 0;
      }
    }
  }

  &__arrow {
    flex-basis: 26px;

    @include media-breakpoint-up(md) {
      flex-basis: 55px;
    }
  }
}
