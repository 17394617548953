/**
 * see $header array in variables to set global headers
 */
@each $id, $breakoints in $heights {
  ##{$id} {
    @each $breakoint, $value in $breakoints {
      @if $breakoint == "global" {
        height: $value;
      } @else {
        @include media-breakpoint-up($breakoint) {
          height: $value;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  .h-#{$breakpoint}-100 {
    @include media-breakpoint-up($breakpoint) {
      height: 100% !important;
    }
  }
}
