header {
  .ce-frame-layout__column & {
    position: relative;

    h2 {
      @include headings("h2");
    }

    h3 {
      @include headings("h3");
    }

    h4 {
      @include headings("h4");
    }

    h5 {
      @include headings("h5");
    }

    &.ce-frame-header__separator {
      // hide separator, if element is directly after a hero-placeholder.
      // if needed the separator nevertheless, use .show-separator
      #hero-placeholder + .ce-frame & {
        &:not(.show-separator) {
          &:before {
            display: none;
          }
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: -$grid-gutter-width;
        left: 50%;
        width: 130px;
        margin-left: -65px;
        max-width: $grid-gutter-width * 4;

        @include media-breakpoint-up(sm) {
          width: 30%;
          margin-left: calc(-15% + #{$grid-gutter-width / 2});
        }

        @include media-breakpoint-up(md) {
          left: 0;
          margin-left: auto;
        }

        height: 1px;
        background: __get($theme-colors, "divider");
      }
    }
  }
}
