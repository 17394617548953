.ce-frame-type-theme_tabbed_content {
  @include bodytext;

  .hero-column {
    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }

  h1 {
    @include headings(h1);
  }

  .tab-content__outer {
    .ce-frame-type-theme_text_media {
      &.ce-frame-layout-0 {
        .ce-frame-layout__column {
          @include media-breakpoint-up(xxl) {
            @include make-col(8);
          }
        }
      }
    }
  }
}

.bg-color-secondary {
  background: __get($theme-colors, "secondary");
}
