.ce-frame-type-theme_event_teaser {
  margin-top: $grid-gutter-width;
  header {
    color: __get($theme-colors, "primary");
  }

  @include media-breakpoint-up(lg) {
    margin-top: $grid-gutter-width * 2;
  }

  @include media-breakpoint-up(xxl) {
    margin-top: $grid-gutter-width * 3;
  }

  .events {
    &__item {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(sm) {
        padding-top: $grid-gutter-width / 2;
        padding-bottom: $grid-gutter-width / 2;
        margin-bottom: 0;
      }

      header {
        margin-bottom: $grid-gutter-width / 2;

        @include media-breakpoint-up(sm) {
          flex: 1 0 percentage(5 / 12);
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: $grid-gutter-width * 2;
          flex: 0 auto;
        }

        h2 {
          font-size: 30px;
          line-height: 1.3;
          margin-bottom: $grid-gutter-width / 2;

          br {
            display: none;

            @include media-breakpoint-up(sm) {
              display: initial;
            }

            @include media-breakpoint-up(xxl) {
              display: none;
            }
          }

          @include media-breakpoint-up(md) {
            font-size: 40px;
          }

          a {
            text-decoration: none;
            &:hover {
              color: inherit;
            }
          }
        }

        time {
          font-size: 20px;
          line-height: 1.2;
          @include medium;

          @include media-breakpoint-up(md) {
            font-size: 28px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 36px;
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 1.9;

        &:last-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          border-left: 1px solid __get($theme-colors, "primary");
          border-right: 1px solid __get($theme-colors, "primary");
        }
      }

      &-wrap {
        border-bottom: 1px solid __get($theme-colors, "primary");
        padding-bottom: $grid-gutter-width / 2;

        @include media-breakpoint-up(sm) {
          margin-left: $grid-gutter-width / -2;
          margin-right: $grid-gutter-width / -2;
          margin-bottom: $grid-gutter-width / 2;
          padding-bottom: 0;

          header,
          div {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;
          }
        }

        @include media-breakpoint-up(lg) {
          border-bottom: none;
          margin-bottom: 0;

          header,
          div {
            padding-left: 0;
            padding-right: 0;
          }

          padding-left: $grid-gutter-width / 2;
          padding-right: $grid-gutter-width / 2;
        }
      }
    }
  }
}

@include ie11-only-wrap-shizzle(
  ".ce-frame-type-theme_event_teaser .events__item header h2 br"
) {
  @include media-breakpoint-up(sm) {
    display: block;
  }
}
