.form-radio {
  flex: 0 0 90px;

  @include media-breakpoint-up(md) {
    flex-basis: 150px;
  }

  display: flex;
  position: relative;

  input[type="radio"] {
    position: absolute;

    &:checked {
      & + label {
        border: 1px solid __get($theme-colors, "primary");
        color: __get($theme-colors, "primary");
      }
    }

    &:focus,
    &:focus-visible,
    .js-focus-visible &.focus-visible {
      outline: 0 !important;
    }

    &:focus + label,
    &:focus-visible + label,
    &.focus-visible + label {
      border-width: 3px;
      color: __get($theme-colors, "primary");
    }
  }

  label {
    border: 1px solid __get($grays, "100");
    color: __get($grays, "100");
    flex: 1 0 auto;
    height: $grid-gutter-width * 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: $border-radius;
    z-index: 1;

    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
}

@include ie11-only-wrap-shizzle('input[type="radio"]') {
  opacity: 0;
}
