ol {
  padding-left: $grid-gutter-width * 2;
  counter-reset: ol-counter;
  margin-bottom: $grid-gutter-width;

  li {
    counter-increment: ol-counter;
    margin-bottom: $grid-gutter-width / 2;

    &:before {
      content: counter(ol-counter) "./";
      padding-right: $grid-gutter-width / 2;
    }

    ol {
      counter-reset: ol-sub-counter;
      margin-top: $grid-gutter-width / 2;

      li {
        counter-increment: ol-sub-counter;

        &:before {
          content: counter(ol-counter) "." counter(ol-sub-counter) "/";
          padding-right: $grid-gutter-width / 2;
        }
      }
    }
  }
}
