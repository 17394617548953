.ce-frame-type-theme_gallery {
  .gallery {
    &__entry {
      &.show {
        height: auto;
        visibility: visible;
        padding: $grid-gutter-width / 2;
      }

      &.hidden {
        height: 0;
        visibility: hidden;
        padding: 0;
      }

      &:focus-within {
        outline: 2px solid __get($theme-colors, "primary");
      }

      &:hover img {
        transform: scale(1.01);
      }
    }

    &__entry-small,
    &__entry-big {
      .glightbox {
        position: relative;
        height: 100%;
        display: block;

        @include media-breakpoint-up(lg) {
          &:after {
            content: "";
            background: url($icon-plus) no-repeat center;
            color: $gallery-icon-color;
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 44px;
            height: 44px;
            background-color: $gallery-icon-bg;
          }
        }
      }
    }

    &__entry-small {
      aspect-ratio: 5/4;

      img {
        aspect-ratio: 5/4;
      }
    }

    &__entry-big {
      aspect-ratio: 4/5;

      img {
        aspect-ratio: 4/5;
      }
    }

    &__entry {
      &-inner {
        display: grid;

        picture {
          overflow: hidden;
        }

        figure {
          display: grid;

          &:focus .download-image-btn {
            outline: none;
          }

          picture {
            grid-row: 1;
            grid-column: 1;
          }

          figcaption {
            grid-row: 2;
            grid-column: 1;
          }
        }

        figure,
        .donwload-image-btn {
          grid-row: 1;
          grid-column: 1;
        }

        .donwload-image-btn {
          z-index: 2;
          width: fit-content;
          height: fit-content;
          align-self: end;
          color: $gallery-icon-color;

          svg {
            padding: $grid-gutter-width * 0.125;
            height: 28px;
            width: auto;
          }
        }
      }
    }
  }
}
