footer {
  &#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
    background: __get($theme-colors, "footer-background");
    margin-top: $grid-gutter-width * 2.5;
    padding-bottom: $grid-gutter-width * 2;

    & ul {
      justify-content: space-evenly;

      @include media-breakpoint-up(md) {
        justify-content: center;
      }

      li {
        & + li {
          @include media-breakpoint-up(md) {
            padding-left: $grid-gutter-width;
          }
        }

        a,
        span {
          color: __get($theme-colors, "navigation-init-color");
          line-height: 2.5;

          @include media-breakpoint-up(md) {
            line-height: 1;
          }
        }

        a {
          text-decoration: underline;
          white-space: nowrap;
        }

        &:first-child {
          @include media-breakpoint-up(lg) {
            margin: 0;
            flex: none;
          }
        }
      }
    }
  }
}

.footer {
  &__inner {
    align-items: center;

    & > * + * {
      margin-top: $grid-gutter-width;

      @include media-breakpoint-up(xxl) {
        margin-top: 0;
      }
    }
  }

  &__brands {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }

    @include media-breakpoint-up(xxl) {
      justify-content: flex-start;
    }

    &-item {
      width: 100%;
      max-width: 158px;

      @include media-breakpoint-up(md) {
        margin-left: $grid-gutter-width / 2;
        margin-right: $grid-gutter-width / 2;
        max-width: 148px;
      }

      & + & {
        margin-top: $grid-gutter-width / 2;

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }

      svg,
      img {
        height: auto;
        width: 100%;
      }

      &-2 {
        width: 112px;

        @include media-breakpoint-up(md) {
          width: 105px;
        }
      }

      &-3 {
        width: 142px;

        @include media-breakpoint-up(md) {
          width: 132px;
        }
      }
    }
  }

  &__links {
    @include media-breakpoint-up(md) {
      order: 2;
    }
  }

  &__copyright {
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      order: 1;
    }
  }
}

@include ie11-only-wrap-shizzle(("footer#footer svg", "footer#footer img")) {
  @include media-breakpoint-up(lg) {
    width: 100px;
  }
}

@include ie11-only-wrap-shizzle("footer#footer ul") {
  justify-content: space-between;
}
