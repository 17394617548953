#header {
  & > .main-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: transparent;
    transition: background-color #{$default-animation-style};
    color: var(--navigation-init-color);

    .tmpl-Event &,
    .tmpl-Homepage & {
      --logo-init-color: #fff;
    }

    &.sticky {
      //--navigation-init-color: #{__get($theme-colors, "navigation-init-color")};
      --logo-init-color: #{__get($theme-colors, "copytext")};
      background: __get($theme-colors, "header-background");
      color: $black !important;
    }

    &[class*="iecp"] {
      background: __get($theme-colors, "header-background");
      color: __get($theme-colors, "navigation-init-color");
    }
  }
  #sub-navigation {
    .tmpl-Event &,
    .tmpl-Homepage & {
      --logo-init-color: #fff;
    }
  }
}

.tmpl-DefaultSubpage {
  #header {
    --navigation-init-color: #{__get($theme-colors, "navigation-init-color")};
  }
}
