.ce-frame-type-theme_text {
  &.ce-frame-layout-0,
  &.ce-frame-layout-indented {
    .ce-frame-layout__column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(xxl) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }

    .ce-frame-layout__container-header .ce-frame-layout__column {
      @include media-breakpoint-up(xxl) {
        @include make-col(8);
        @include make-col-offset(1);
      }
    }

    .btn {
      margin-top: $grid-gutter-width;

      & + .btn {
        margin-left: __get($spacers, "hg");
      }
    }

    .ce-frame-type-theme_text--header {
      h2,
      h3,
      h4 {
        @include headings(h2);
        font-family: $font-family-bold;
        margin-top: 0;
      }
    }

    .ce-frame-type-theme_text--subheader {
      order: -1;
      color: $primary;
      font-size: 16px;
      margin-bottom: 0;

      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }

    @include bodytext();

    .bodytext {
      text-align: left;
      > *:first-child {
        margin-top: 0;
      }
    }
  }
}
