$lightbox-loader-height: 120px;
$lightbox-loader-width: 120px;
$loader-size: 48px;

.lightbox {
  &-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -$loader-size;
    margin-top: -$loader-size;
    z-index: 100;

    &__inline {
      p {
        z-index: 1;
      }
      &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        background: rgba($white, 0.75);
        width: 100%;
        height: 100%;
      }
    }
  }

  &-loader {
    width: $loader-size;
    height: $loader-size;
    display: inline-block;
    position: fixed;
    color: var(--primary);
    animation: rotation 1s linear infinite;
    z-index: 1000;
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: $loader-size / 2;
      height: $loader-size / 2;
      top: 50%;
      left: 50%;
      transform: scale(0.5) translate(0, 0);
      background-color: var(--primary);
      border-radius: 50%;
      animation: animloader38 1s infinite ease-in-out;
      z-index: 1000;
    }
    &:before {
      transform: scale(0.5) translate(-$loader-size, -$loader-size);
    }
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.75);
    z-index: 50;
    transition: all 0.2s ease-in-out;
  }

  &.loaded:before {
    background: $white;
  }

  &-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
    scroll-behavior: smooth;
  }

  &-close {
    position: absolute;
    top: $grid-gutter-width / 2;
    right: $grid-gutter-width / 2;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    background: none;

    svg {
      transform: scale(0.75);
    }
  }

  &__pagination {
    border: none;
    background: none;
    padding: 0;
    display: block;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      margin-top: -6px;
    }

    span {
      font-size: 1.125rem;
      line-height: 1;

      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
      }
    }
  }

  &__prev {
    svg {
      transform: scale(1.5) rotate(180deg);

      @include media-breakpoint-up(md) {
        transform: rotate(180deg);
      }
    }
  }

  &__next {
    svg {
      transform: scale(1.5);

      @include media-breakpoint-up(md) {
        transform: scale(1);
      }
    }
  }
}

body.lightbox-open {
  overflow: hidden;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader38 {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
