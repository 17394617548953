ul {
  .ce-frame-layout__column-text &,
  .ce-frame-layout__column-text-media & {
    padding-left: $grid-gutter-width;

    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width * 2;
    }

    margin-bottom: $grid-gutter-width / 2;

    list-style: initial;

    li {
      margin-bottom: $grid-gutter-width / 4;

      ul {
        margin-top: $grid-gutter-width / 2;
      }
    }
  }
}
