@mixin css-grid($layer: "wrap") {
  @if $layer == "wrap" {
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: auto;
    grid-template-columns: auto;

    -ms-grid-rows: auto;
    grid-template-rows: auto;

    grid-gap: 0;
  }

  @if $layer == "layer-1" {
    -ms-grid-column: 1;
    grid-column: 1 / 1;

    -ms-grid-row: 1;
    grid-row: 1;

    z-index: 1;
  }

  @if $layer == "layer-2" {
    -ms-grid-column: 1;
    grid-column: 1 / 1;

    -ms-grid-row: 1;
    grid-row: 1;
  }
}
