.ce-frame-type-list-bbboilerplate_pi4 {
  h1 {
    @include headings("h1");
  }

  .teasertext {
    @include medium;
  }

  @include bodytext;

  .speaker-session__list {
    display: flex;
    flex-direction: column;
    margin-bottom: $grid-gutter-width * 3;

    header {
      text-align: center;
      order: 0;
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      small {
        display: block;
        @include regular;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: $grid-gutter-width / 2;
      }

      & + div {
        order: 2;

        @include media-breakpoint-up(md) {
          order: 1;
        }
      }
    }

    figure {
      order: 1;
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(md) {
        order: 2;
      }

      & + div {
        order: 3;
      }
    }

    &-informations {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: __get($theme-colors, "primary");
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
      }

      &-date {
        @include headings("h3");
        @include medium;
        margin-bottom: $grid-gutter-width / 2;
      }

      &-info {
        @include medium;
        font-size: 16px;
        line-height: 1.6;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          justify-content: space-between;
        }

        @include media-breakpoint-up(lg) {
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
  }
}
