@mixin headings($type) {
  @if $type == "h1" {
    font-size: 2.375rem;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: 2.75rem;
      line-height: 1.25;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3.375rem;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: 5.75rem;
    }
  }

  @if $type == "h2" {
    font-size: 1.625rem;
    line-height: 1.3;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      line-height: 1.4;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: 3rem;
      line-height: 1.2;
    }
  }

  @if $type == "h3" {
    font-size: 1.5rem;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: 1.625rem;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: 1.75rem;
    }
  }

  @if $type == "h4" {
    font-size: 22px;

    @include media-breakpoint-up(md) {
      font-size: 26px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 28px;
    }

    line-height: 1.4;
  }

  @if $type == "h5" {
    font-size: 24px;
    line-height: 1.5;

    @include media-breakpoint-up(md) {
      font-size: 35px;
      line-height: 1.7;
    }
  }
}
