.hero {
  &.hero {
    @include make-col-ready();
    @include make-col(12);
  }

  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    margin-bottom: 60px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__title.hero__title,
  &__text.hero__text,
  &__meta.hero__meta {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: none;
    padding: 0;

    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }

  &__text.hero__text {
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(8);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(5);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }
}
