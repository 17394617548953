.link-decoration {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $grid-gutter-width / -4;
    height: 3px;
    right: 100%;
    background: __get($theme-colors, "primary");
    transition: all 0.2s ease-in-out;
  }
}
