.dce-text-with-img {
  max-width: none;
  position: relative;
  padding: 0;

  & > div {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(9);
      @include make-col-offset(1);
    }

    .frame-layout-1 & {
      @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }
  }
}
