#navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > ul {
    margin: 0;
    display: flex;
    font-size: 18px;
    margin-left: $navigation-gutter / -4;
    margin-right: $navigation-gutter / -4;

    @include media-breakpoint-up(md) {
      margin-left: $navigation-gutter / -2;
      margin-right: $navigation-gutter / -2;
    }

    & > li {
      display: flex;
      padding-left: $navigation-gutter / 4;
      padding-right: $navigation-gutter / 4;

      @include media-breakpoint-up(md) {
        padding-left: $navigation-gutter / 2;
        padding-right: $navigation-gutter / 2;
      }

      & > a {
        font-size: 18px;
        color: inherit;
        display: flex;
        align-items: center;
        z-index: 3;
        @include medium;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 1px;
          background: transparent;
          transition: all #{$default-animation-style};
        }

        &:hover {
          text-decoration: none;

          &:after {
            background: var(--navigation-init-color);
          }
        }

        &[aria-current="page"] {
          position: relative;

          &:after {
            background: var(--navigation-init-color);
            .sticky & {
              background: __get($theme-colors, "copytext");
            }
          }
        }

        transition: color #{$default-animation-style};
      }

      &.small {
        position: relative;

        a {
          @include regular;
          font-size: 16px;
          padding-top: 2px;
          text-transform: none;
        }
      }

      &.separator {
        position: relative;

        @include media-breakpoint-up(md) {
          padding-left: $navigation-gutter * 1.5;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            width: 1px;
            background: var(--navigation-init-color);

            .sticky & {
              background: $black;
            }

            left: $navigation-gutter / 2;
            z-index: 3;

            height: 50px;
            margin-top: -25px;

            @include media-breakpoint-up(lg) {
              height: 66px;
              margin-top: -33px;
            }
          }

          &.right {
            padding-right: $navigation-gutter * 1.5;
            padding-left: $navigation-gutter / 2;

            &:before {
              left: auto;
              right: $navigation-gutter / 2;
            }
          }
        }
      }
    }
  }

  .hamburger {
    z-index: 3;

    &.is-active {
      & + .overlay {
        transform: translateY(0);
        top: 72px;
        height: calc(100% - 72px);
        scroll-behavior: smooth;

        @include media-breakpoint-up(lg) {
          top: 0;
          height: 100%;
          padding-top: 100px;
          box-shadow: 0 0 10px rgba(#000, 0.5);
        }
      }
    }
  }

  .overlay {
    transform: translateY(-100%);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    background: __get($theme-colors, "navigation-background");

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  & .language-selector-burgernav {
    padding: $grid-gutter-width / 2 0;
    font-size: 16px;

    ul {
      li {
        &:first-child {
          &:before {
            content: "";
          }
        }

        &:before {
          content: " / ";
          margin-left: $grid-gutter-width / 8;
        }
      }
    }

    & .language-selctor {
      padding-right: 0.2em;
    }
  }

  & .footer-nav {
    & li {
      padding: calc(#{$grid-gutter-width} / 4) 0;

      & a {
        letter-spacing: 0.05em;
      }
    }
  }
}

#header-sub-wrap #subnavigation {
  position: absolute;
  top: __get($heights, "header-wrap.global") + $grid-gutter-width / 2;
  left: 0;
  padding: 0 $grid-gutter-width $grid-gutter-width $grid-gutter-width;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;

  --subnavigation-color: var(--navigation-init-color);
  --subnavigation-color-separator: var(--subnavigation-color);
  --subnavigation-color-main: var(--subnavigation-color);

  @include media-breakpoint-up(lg) {
    top: __get($heights, "header-wrap.lg") + $grid-gutter-width / 2;
    padding-top: $grid-gutter-width;
  }

  .subnavigation-icon {
    position: relative;
    padding-right: $grid-gutter-width / 2;
    margin-right: $grid-gutter-width / 2;
    display: flex;

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      top: 10px;
      bottom: 10px;
      background-color: #000;
    }
  }

  @include media-breakpoint-down(md) {
    &:has(.sub-icon) ul li:nth-child(2) {
      padding-left: 0;
    }
  }

  &:not(:has(.sub-icon)) ul li:first-child {
    padding-left: 0;
  }

  ul {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }

    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    margin-bottom: 0;
    color: var(--subnavigation-color);

    li {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;

      &.subnavigation-first-item {
        position: relative;
        padding-right: $grid-gutter-width;
        margin-right: $grid-gutter-width / 2;
        display: flex;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          background: var(--subnavigation-color-separator);
        }

        time {
          font-size: 16px;
          display: inline-block;
          margin: 2px 0 0 $grid-gutter-width / 2;
        }

        span {
          @include bold;
          font-size: 18px;
        }
      }

      a {
        font-size: 18px;
        color: var(--subnavigation-color-main);
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 1px;
          background: transparent;
          transition: all 0.2s ease-in-out;
        }

        &:hover {
          text-decoration: none;

          &:after {
            background: var(--subnavigation-color-main);
          }
        }
      }

      &.active {
        a {
          @include bold;
          color: var(--subnavigation-color);

          &:after {
            background: var(--subnavigation-color-main);
          }
        }
      }
    }
  }
}

@include navigation;

@include ie11-only-wrap-shizzle("#navigation > ul > li.separator:before") {
  @include media-breakpoint-up(md) {
    background: __get($theme-colors, "navigation-init-color");
  }
}
