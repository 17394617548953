.btn {
  @include medium;

  padding: 0.5rem 1rem;
  text-decoration: none !important;
  text-transform: none !important;

  @include media-breakpoint-up(lg) {
    padding: 1rem 1.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.25rem;
  }

  & + & {
    margin-top: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-left: $grid-gutter-width * 0.5;
    }
  }

  &[class*="-outline-"] {
    text-decoration: none;
    text-transform: none;
  }

  &[href*="youtube.com"] {
    display: inline-flex;
    align-items: center;

    &:before {
      content: "";
      width: 17px;
      height: 17px;
      margin-right: $grid-gutter-width / 4;
      @include transition($btn-transition-icon);
    }

    @each $color, $value in $theme-colors {
      &.btn-#{"" + $color} {
        &:before {
          $original: #{color-yiq($value)};
          $colorstring: str-replace($original, "#", "%23");
          background-position: left center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L0.249999 15.3612L0.25 0.638783L13 8Z' fill='#{$colorstring}'/%3E%3C/svg%3E");
        }
      }

      &.btn-outline-#{"" + $color} {
        &:before {
          $original: #{$value};
          $colorstring: str-replace($original, "#", "%23");
          background-position: left center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L0.249999 15.3612L0.25 0.638783L13 8Z' fill='#{$colorstring}'/%3E%3C/svg%3E");
        }

        &:hover:before {
          $originalhover: #{color-yiq($value)};
          $colorstringhover: str-replace($originalhover, "#", "%23");
          background-position: left center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L0.249999 15.3612L0.25 0.638783L13 8Z' fill='#{$colorstringhover}'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &[data-url*="onlinelivetest"],
  &[href*="onlinelivetest"] {
    display: inline-flex;
    align-items: center;

    &:before {
      $colorstring: str-replace(#{$primary}, "#", "%23");
      content: "";
      width: 17px;
      height: 17px;
      margin-right: $grid-gutter-width / 4;
      @include transition($btn-transition-icon);
      background: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L0.249999 15.3612L0.25 0.638783L13 8Z' fill='#{$colorstring}'/%3E%3C/svg%3E%0A")
        left center no-repeat;
    }

    &:hover {
      &:before {
        $colorstring: str-replace(#{$black}, "#", "%23");
        background: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L0.249999 15.3612L0.25 0.638783L13 8Z' fill='#{$colorstring}'/%3E%3C/svg%3E%0A")
          left center no-repeat;
      }
    }

    &.btn-primary {
      $colorstring: str-replace(#{$white}, "#", "%23");
      &:before {
        background: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 8L0.249999 15.3612L0.25 0.638783L13 8Z' fill='#{$colorstring}'/%3E%3C/svg%3E%0A")
          left center no-repeat;
      }
    }
  }
}

.btn-white {
  @include button-variant($white, $white, $primary, $primary, $white, $white);
}
