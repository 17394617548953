@mixin focus() {
  &:focus,
  &[class]:focus,
  &:active,
  &[class]:active {
    outline: 2px solid transparent;
    outline-offset: 6px;
    border-radius: 0;
  }

  &:focus-visible,
  &[class]:focus-visible,
  &.focus-visible {
    outline: 2px solid __get($theme-colors, "primary");
    outline-offset: 6px;
    border-radius: 0;
  }
}
