.container {
  &-fluid {
    @each $breakpoint, $width in $container-max-widths {
      @if ($breakpoint == "ultrawide") {
        @include media-breakpoint-up($breakpoint) {
          max-width: 1900px;
        }
      } @else {
        @if ($width > 0) {
          @include media-breakpoint-up($breakpoint) {
            max-width: $width;
          }
        }
      }
    }
  }
}
