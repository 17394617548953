legend {
  margin: 0;
  color: __get($theme-colors, "primary");
  margin-bottom: $grid-gutter-width / 4;
  font-size: 16px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}
