.website-logo {
  z-index: 3;

  svg {
    max-width: 120px;
    width: 100%;

    @include media-breakpoint-up(xl) {
      max-width: 185px;
    }
  }
}

@include ie11-only-wrap-shizzle(".website-logo svg st3") {
  fill: __get($theme-colors, "primary") !important;
}

.sub-icon {
  &-de {
    @include media-breakpoint-up(lg) {
      &:after {
        content: "";
        position: absolute;
        top: 25px;
        bottom: 35px;
        width: 1px;
        left: 121px;
        background: var(--navigation-init-color);
      }
    }

    svg {
      width: 60px;
      @include media-breakpoint-up(lg) {
        width: 73px;
        margin-right: $grid-gutter-width;
      }
    }
  }

  &-en {
    @include media-breakpoint-up(lg) {
      &:after {
        content: "";
        position: absolute;
        top: 25px;
        bottom: 35px;
        width: 1px;
        left: 187px;
        background: var(--navigation-init-color);
      }
    }

    svg {
      width: 100px;
      @include media-breakpoint-up(lg) {
        width: 139px;
        margin-right: $grid-gutter-width;
      }
    }
  }
}
