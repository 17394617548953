.btn {
  &-bar {
    margin-bottom: $grid-gutter-width / 2;

    p {
      font-size: 1rem;
      display: flex;
      justify-content: center;

      @include media-breakpoint-up(md) {
        justify-content: flex-start;
        margin-left: $grid-gutter-width / -2;
        margin-right: $grid-gutter-width / -2;
      }

      a {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
      }
    }
  }
}
