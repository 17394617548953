@charset "UTF-8";

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 42px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 10px;
$hamburger-layer-color: __get($theme-colors, primary);
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(100%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

$hamburger-types: (squeeze);

@import "../../../node_modules/hamburgers/_sass/hamburgers/base";
@import "../../../node_modules/hamburgers/_sass/hamburgers/types/squeeze";

.hamburger {
  height: 24px;

  &-inner,
  &.is-active &-inner {
    background-color: var(--navigation-init-color);

    .sticky & {
      background: $black;
    }

    &:after,
    &:before {
      background-color: var(--navigation-init-color);

      .sticky & {
        background: $black;
      }
    }
  }

  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(sm) {
    transform: scale(0.8333);
  }
}

@include ie11-only-wrap-shizzle(".hamburger") {
  height: 48px;
  padding: 0 5px;
  border-radius: 5px;
}

@include ie11-only-wrap-shizzle(".hamburger .hamburger-box") {
  height: 48px;
}

@include ie11-only-wrap-shizzle(
  (
    ".hamburger .hamburger-box .hamburger-inner",
    ".hamburger .hamburger-box .hamburger-inner:after",
    ".hamburger .hamburger-box .hamburger-inner:before"
  )
) {
  background: __get($theme-colors, "navigation-init-color");
}
