@mixin navigation() {
  .navigation {
    font-size: 16px;
    line-height: 1.7;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 1.5;
    }

    @include media-breakpoint-up(xxl) {
      line-height: 1.7;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: 20px;
    }

    ul {
      position: relative;
      display: none;
      width: 100%;
      padding: 0 16px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        width: 100%;
        right: calc(-100% - #{$grid-gutter-width * 2.5});
        top: 0;
      }
    }

    a:not(.btn),
    button:not(.btn) {
      text-decoration: none;
      font-size: 1.2em;
      @include medium;

      @include media-breakpoint-up(sm) {
        font-size: 1.4em;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.8em;
        white-space: nowrap;
      }

      &.is-active {
        text-decoration: underline;
        text-underline-offset: $grid-gutter-width * 0.25;
      }

      &:hover {
        color: __get($theme-colors, "primary");

        @include media-breakpoint-up(lg) {
          text-decoration: underline;
          text-underline-offset: $grid-gutter-width * 0.25;
          border-bottom: 0;
        }
      }
    }

    &.navigation {
      &__main {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(lg) {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: -$grid-gutter-width * 1.5;
            height: 100%;
            width: 1px;
            background: __get($theme-colors, "primary");
          }
        }

        @include media-breakpoint-up(lg) {
          width: auto;
        }

        li.has-sub.is-small:after,
        li.has-sub:after {
          @include bg-color("secondary");
          z-index: -2;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }

        & > li {
          display: flex;
          flex-direction: column;
          position: relative;
          line-height: $grid-gutter-width * 2;

          @include media-breakpoint-up(lg) {
            position: static;
          }

          ul {
            padding-bottom: $grid-gutter-width / 2;

            li {
              line-height: 2;
            }
          }

          &:before {
            content: "";
            position: absolute;
            width: calc(100% + #{$grid-gutter-width});
            left: -$grid-gutter-width / 2;
            height: 1px;
            background-color: __get($theme-colors, "primary");

            @include media-breakpoint-up(lg) {
              display: none;
            }
          }

          @include media-breakpoint-up(lg) {
            flex-direction: row;
          }

          &:last-child {
            &:after {
              content: "";
              position: absolute;
              width: calc(100% + #{$grid-gutter-width});
              left: -$grid-gutter-width / 2;
              height: 1px;
              bottom: 0;
              background-color: __get($theme-colors, "primary");

              @include media-breakpoint-up(lg) {
                display: none;
              }
            }
          }

          & > a,
          & > button {
            border: none;
            background-color: transparent;
            color: var(--primary);
            text-transform: uppercase;
            display: flex;
            align-items: center;
            flex: 1 0 100%;
            justify-content: space-between;
          }

          &.has-sub {
            & > a,
            & > button {
              font-size: 1.45em;
              @include bold;
              letter-spacing: 0.1em;
              display: flex;
              align-items: center;
              flex: 1 0 100%;
              justify-content: space-between;
              padding: 0;
              position: relative;

              &:before {
                @include bg-color("nav-light");
                z-index: -1;

                @include media-breakpoint-up(lg) {
                  display: none;
                }
              }

              @include media-breakpoint-up(sm) {
                font-size: 1.75em;
              }

              @include media-breakpoint-up(md) {
                font-size: 2em;
              }

              @include media-breakpoint-up(lg) {
                padding-right: $grid-gutter-width * 2;
              }

              em {
                @include arrow("down");
                right: 13px;
                top: 22px;
                transition: all 0.2s ease-in-out;

                @include media-breakpoint-up(lg) {
                  @include arrow("right");
                  top: 22px;
                }
              }

              &.is-active {
                span {
                  text-decoration: underline;
                  text-underline-offset: $grid-gutter-width * 0.25;
                }

                em {
                  @include arrow("up");
                  top: 27px;

                  @include media-breakpoint-up(lg) {
                    @include arrow("right");
                    top: 22px;
                  }
                }
              }

              &.is-active {
                & + ul {
                  button.is-active + .overlay & {
                    display: block;
                  }
                }

                &:before {
                  @include bg-color("secondary");
                  z-index: -1;
                }
              }
            }
          }

          &.is-small {
            & > a,
            & > button {
              font-size: 1.4em;
              text-transform: none;

              @include media-breakpoint-up(lg) {
                font-size: 1.8em;
                padding: 0;
                padding-right: $grid-gutter-width;
              }
            }

            &.has-sub {
              & > a,
              & > button {
                @include medium;
                position: relative;

                &:after {
                  @include bg-color("nav-light");
                  z-index: -1;

                  @include media-breakpoint-up(lg) {
                    display: none;
                  }
                }

                em {
                  @include arrow("down");
                  right: 13px;
                  top: 22px;
                  transition: all 0.2s ease-in-out;
                  transform: rotate(45deg) scale(0.8);

                  @include media-breakpoint-up(lg) {
                    transform: rotate(-45deg) scale(0.8);
                  }
                }

                &.is-active {
                  @include bold;

                  em {
                    @include arrow("up");
                    top: 27px;
                    transform: rotate(-135deg) scale(0.8);

                    @include media-breakpoint-up(lg) {
                      transform: rotate(-45deg) scale(0.8);
                      top: 22px;
                    }
                  }

                  &:after {
                    @include bg-color("secondary");
                    z-index: -1;
                    border-bottom: 0;

                    @include media-breakpoint-up(lg) {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          &.is-language {
            & > a {
              font-size: 1.2em;
              text-transform: none;
            }
          }
        }
      }
    }
  }

  @include ie11-only-wrap-shizzle(".navigation.navigation__main") {
    @include media-breakpoint-up(lg) {
      width: 65vh;
    }
  }

  @include ie11-only-wrap-shizzle(".navigation.navigation__main > li") {
    display: block;
  }

  @include ie11-only-wrap-shizzle(
    (
      ".navigation.navigation__main > li.has-sub > a",
      ".navigation.navigation__main > li.has-sub > button"
    )
  ) {
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      padding-right: $grid-gutter-width;
    }
  }
}
